import React from "react";

import { Pagination, Show } from "@adl/smart-ui-library";
import LoadingScreen from "@/components/LoadingScreen";
import { Alert, Box, Typography } from "@mui/material";
import FileCard from "@/components/FileCard";
import { v4 } from "uuid";
import { motion } from "framer-motion";
import * as Styled from "./DocumentList.styles";
import { IDocument, IServiceResponce } from "../../../redux/store.types";
import { ANNIMATION_SPEED } from "@/constants";

interface IProps extends IServiceResponce<IDocument> {
  onUpdate?: (skip: number) => void;
  onSelectDocument: (data: IDocument) => void;
  onDeleteFile?: (id: string | number) => void;
  isRefresh?: boolean;
}

const DocumentList = ({
  onSelectDocument,
  onUpdate,
  data,
  pagination,
  isLoading,
  hasError,
  onDeleteFile,
  isRefresh,
}: IProps) => {
  const reveasedDetails = data ? [...data].reverse() : null;

  return (
    <Styled.DocumentList>
      <Typography variant="h6">Uploaded Files</Typography>
      <Show>
        <Show.When isTrue={hasError}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: isRefresh ? 0 : ANNIMATION_SPEED }}
          >
            <Alert severity="error">Error fetching data</Alert>
          </motion.div>
        </Show.When>
        <Show.When isTrue={isLoading}>
          <LoadingScreen />
        </Show.When>
        <Show.Else>
          <Box sx={{ marginTop: "20px" }}>
            <Show>
              <Show.When isTrue={reveasedDetails?.length === 0}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: ANNIMATION_SPEED }}
                >
                  <Alert severity="info">
                    No files available for this case
                  </Alert>
                </motion.div>
              </Show.When>
              <Show.Else>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: ANNIMATION_SPEED }}
                >
                  <Styled.DocumentListWrapper>
                    {reveasedDetails?.map((item) => {
                      return (
                        <FileCard
                          key={v4()}
                          data={item}
                          onSelect={(data: IDocument) => {
                            onSelectDocument(data);
                          }}
                          onDeleteFile={onDeleteFile}
                        />
                      );
                    })}

                    <Styled.DocumentListWrapperPagination>
                      {pagination && (
                        <Pagination
                          totalResults={pagination.total}
                          resultsPerPage={10}
                          page={pagination.skip / 10 + 1}
                          onChange={(value) =>
                            onUpdate && onUpdate(value.value as number)
                          }
                        />
                      )}
                    </Styled.DocumentListWrapperPagination>
                  </Styled.DocumentListWrapper>
                </motion.div>
              </Show.Else>
            </Show>
          </Box>
        </Show.Else>
      </Show>
    </Styled.DocumentList>
  );
};

export default DocumentList;

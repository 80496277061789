import { Box } from "@mui/material";
import React from "react";

type Props = {
  width?: number;
  height?: number;
  handleClick?: () => void;
};

const VaultTextLogo = ({ width, height, handleClick }: Props) => {
  return (
    <Box
      data-testid="vault-text-logo"
      onClick={handleClick}
      sx={{ cursor: handleClick ? "pointer" : "default", width: "100px" }}
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1645.64 368.7"
      >
        <g>
          <path
            fill="#0d003f"
            d="m73.14,0l94.32,296.07L261.27,0h72.63l-128.62,360.13h-76.16L0,0h73.14Z"
          ></path>
          <path
            fill="#0d003f"
            d="m563.9,270.85h-141.73l-28.25,89.28h-71.12L453.44,0h80.2l129.12,360.13h-70.61l-28.25-89.28Zm-17.15-53.97l-48.92-155.35h-9.08l-49.43,155.35h107.43Z"
          ></path>
          <path
            fill="#0d003f"
            d="m702.09,221.42V0h65.57v219.41c0,57.5,28.25,89.78,78.68,89.78,54.98,0,78.68-26.73,78.68-89.78V0h66.07v221.42c0,96.34-49.93,147.28-144.76,147.28s-144.25-49.93-144.25-147.28Z"
          ></path>
          <path
            fill="#0d003f"
            d="m1141.4,0v302.12h180.06v58h-246.14V0h66.07Z"
          ></path>
          <path
            fill="#0d003f"
            d="m1287.66,58V0h290.52v58h-112.48v302.12h-66.07V58h-111.97Z"
          ></path>
        </g>
        <circle fill="#16de6a" cx="1608.3" cy="325.08" r="37.34"></circle>
      </svg>
    </Box>
  );
};

export default VaultTextLogo;

import { Accordion, AccordionDetails } from "@mui/material";
import { styled } from "@mui/system";

export const TaskAccordion = styled(Accordion)(({ theme }) => ({
  background: "none",
  border: `1px solid ${theme.palette.grey[400]}`,
}));

export const TaskAccordionDetails = styled(AccordionDetails)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  padding: "20px",
}));

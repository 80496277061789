import { Box } from "@mui/material";
import { styled } from "@mui/system";

interface TimeLineNodeProps {
  bgColour: string;
}

export const TimeLineNode = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bgColour",
})<TimeLineNodeProps>(({ bgColour }) => ({
  width: "20px",
  height: "20px",
  minHeight: "20px",
  backgroundColor: bgColour,
  borderRadius: "5px",
}));

interface TimeLineLineProps {
  orientation: "horizontal" | "vertical";
}

export const TimeLineLine = styled(Box, {
  shouldForwardProp: (prop) => prop !== "orientation",
})<TimeLineLineProps>(({ theme, orientation }) => ({
  position: "relative",
  height: orientation === "horizontal" ? "4px" : "100%",
  width: orientation === "horizontal" ? "100%" : "4px",
  backgroundColor: theme.palette.grey[400],
  borderRadius: "5px",
  borderRight: "none",
}));

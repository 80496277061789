import React from "react";
import { formatDate } from "@/utils";
import { Chip } from "@mui/material";
import * as Styled from "./TaskChips.styles";
import { RenderIf } from "@adl/smart-ui-library";
import { ITask } from "@/redux/store.types";

type Props = {
  task: ITask;
};

const TaskChips = ({ task }: Props) => {
  const { createdAt, createdBy, verified, verifiedAt, verifiedBy, document } =
    task;

  return (
    <Styled.TaskChips>
      <Chip variant="outlined" label={`Created: ${formatDate(createdAt)}`} />
      <Chip variant="outlined" label={`Created by: ${createdBy}`} />
      <RenderIf isTrue={verified}>
        <RenderIf isTrue={Boolean(verifiedAt)}>
          <Chip
            variant="outlined"
            label={`Validated: ${formatDate(verifiedAt)}`}
          />
        </RenderIf>

        <Chip variant="outlined" label={`Validated by: ${verifiedBy}`} />

        <Chip variant="outlined" label={`Category: ${document.categoryName}`} />
      </RenderIf>
    </Styled.TaskChips>
  );
};

export default TaskChips;

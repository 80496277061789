import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const HorzontalTimeLine = styled(Box)(() => ({
  width: "100%",
  height: "80px",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: "10px",
  alignItems: "center",
  marginBottom: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",
}));

export const HorzontalTimeLineContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
  position: "relative",
}));

export const HorzontalTimeLineContentLabel = styled(Box)(() => ({
  position: "absolute",
  top: "30px",
}));

import React from "react";
import { useAppSelector } from "@/hooks";
import { selectSelectedCase, selectSelectedDocument } from "@/redux/casesSlice";
import { selectTasks } from "@/redux/tasksSlice";
import { Show } from "@adl/smart-ui-library";
import SelectedCasePage from "./SelectedCasePage";
import MyCasesPage from "./MyCasesPage";
import SelectedDocumentPage from "./SelectedDocumentPage";
import TasksPage from "./TasksPage";

const Router = () => {
  const selectedCase = useAppSelector(selectSelectedCase);
  const selectedDocument = useAppSelector(selectSelectedDocument);
  const selectedTasks = useAppSelector(selectTasks);

  return (
    <Show>
      <Show.When isTrue={Boolean(selectedTasks)}>
        <TasksPage />
      </Show.When>
      <Show.When isTrue={Boolean(selectedCase) && !selectedDocument}>
        <SelectedCasePage />
      </Show.When>
      <Show.When isTrue={Boolean(selectedDocument) && Boolean(selectedCase)}>
        <SelectedDocumentPage />
      </Show.When>
      <Show.Else>
        <MyCasesPage />
      </Show.Else>
    </Show>
  );
};

export default Router;

import { Box } from "@mui/material";
import { styled } from "@mui/system";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const TaskHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 20px",
}));

export const TaskHeaderTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  color: theme.palette.primary.main,
}));

export const TaskHeaderVarifiedIcon = styled(CheckCircleOutlineIcon)(
  ({ theme }) => ({
    color: theme.palette.success.main,
  })
);

export const TaskHeaderUnvarifiedIcon = styled(PanoramaFishEyeIcon)(
  ({ theme }) => ({
    color: theme.palette.error.main,
  })
);

import React from "react";
import { v4 } from "uuid";
import { ITimeLineData } from "../TimeLine";
import { RenderIf } from "@adl/smart-ui-library";
import * as Styled from "./VerticalTimeLine.styles";
import { TimeLineLine, TimeLineNode } from "../TimeLine.styles";
import { getStatusColour } from "@/utils";

type Props = {
  direction?: "horizontal" | "vertical";
  data: ITimeLineData[];
  limit?: number;
};

export const VerticalTimeLine = ({ data, limit = 10 }: Props) => {
  return (
    <Styled.VerticalTimeLine>
      {data.map((item, index) => {
        return (
          <React.Fragment key={v4()}>
            <RenderIf isTrue={Boolean(index < limit)}>
              <Styled.VerticalTimeLineContent>
                <Styled.VerticalTimeLineContentLeft>
                  <TimeLineNode bgColour={getStatusColour(item.status)} />
                  <RenderIf
                    isTrue={
                      Boolean(index < data.length - 1) &&
                      Boolean(index < limit - 1)
                    }
                  >
                    <TimeLineLine orientation="vertical" />
                  </RenderIf>
                </Styled.VerticalTimeLineContentLeft>
                <Styled.VerticalTimeLineContentRight>
                  {item.renderer}
                </Styled.VerticalTimeLineContentRight>
              </Styled.VerticalTimeLineContent>
            </RenderIf>
          </React.Fragment>
        );
      })}
    </Styled.VerticalTimeLine>
  );
};

export default VerticalTimeLine;

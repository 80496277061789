import { Button, AccordionSummary, Typography } from "@mui/material";
import React, { useEffect } from "react";

import TaskChips from "./TaskChips";
import {
  RenderIf,
  PrivacyLevelsSelector,
  EditableCategories,
  EditableSummary,
} from "@adl/smart-ui-library";
import TaskHeader from "./TaskHeader";
import { useAppSelector } from "@/hooks";
import {
  selectDocumentCatagories,
  selectDocumentPrivacyLevels,
} from "@/redux/casesSlice";
import * as Styled from "./TaskCard.styles";
import {
  IVerifyTaskPayload,
  ITask,
  IDocumentPrivacy,
} from "@/redux/store.types";

type Props = {
  task: ITask;
  handleVerify: (payload: IVerifyTaskPayload) => void;
};

const TaskCard = ({ task, handleVerify }: Props) => {
  const [summary, setSummary] = React.useState<string>(task?.document?.summary);
  const [categoryId, setCategoryId] = React.useState<string | number>(
    task?.document?.categoryId
  );
  const [privacyLevels, setPrivacyLevels] = React.useState<IDocumentPrivacy>(
    task?.document?.privacyLevels
  );

  const categories = useAppSelector(selectDocumentCatagories);
  const levels = useAppSelector(selectDocumentPrivacyLevels);

  const onVerify = () => {
    handleVerify({
      taskId: task.taskId,
      taskType: "DocumentVerification",
      document: {
        summary,
        categoryId,
        privacyLevels,
      },
    });
  };

  useEffect(() => {
    setSummary(task?.document?.summary);
    setCategoryId(task?.document?.categoryId);
    setPrivacyLevels(task?.document?.privacyLevels);
  }, [task]);

  return (
    <Styled.TaskAccordion>
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <TaskHeader task={task} />
      </AccordionSummary>
      <Styled.TaskAccordionDetails>
        <Typography variant="h6">Overview</Typography>
        <TaskChips task={task} />

        <Typography variant="h6">File Category</Typography>
        <EditableCategories
          value={categoryId}
          onUpdate={(value: { id: string | number; name: string }) => {
            setCategoryId(value.id);
          }}
          disabled={task?.verified}
          categories={categories}
        />
        <Typography variant="h6">Privacy Level</Typography>
        <PrivacyLevelsSelector
          values={privacyLevels}
          onUpdate={(value: IDocumentPrivacy) => {
            setPrivacyLevels(value);
          }}
          disabled={task?.verified}
          levels={levels}
        />
        <EditableSummary
          text={summary}
          onUpdate={(text: string) => {
            if (text === summary) return;
            setSummary(text);
          }}
          disabled={task?.verified}
        />
        <RenderIf isTrue={!task?.verified}>
          <Button
            data-testid="verify-button"
            variant="contained"
            disableElevation
            disabled={!summary}
            onClick={onVerify}
          >
            Commit
          </Button>
        </RenderIf>
      </Styled.TaskAccordionDetails>
    </Styled.TaskAccordion>
  );
};

export default TaskCard;

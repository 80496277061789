import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";
import { ITask, IVerifyTaskPayload } from "./store.types";

interface ITasksState {
  isLoading: boolean;
  outstanding: ITask[] | null;
  completed: ITask[] | null;
  selectedTasks: boolean;
  updatedTasks: boolean;
  hasError: boolean;
}

export const NAME = "tasks";

const initialState: ITasksState = {
  isLoading: false,
  outstanding: null,
  completed: null,
  selectedTasks: false,
  updatedTasks: false,
  hasError: false,
};

export const getOutstandingTasks = createAsyncThunk(
  `${NAME}/getOutstandingTasks`,
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`tasks?verified=false`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCompletedTasks = createAsyncThunk(
  `${NAME}/getCompletedTasks`,
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`tasks?verified=true`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putVarifiedTasks = createAsyncThunk(
  `${NAME}/putVarifiedTasks`,
  async (args: IVerifyTaskPayload, thunkAPI) => {
    try {
      const response = await axiosInstance.put(`tasks`, args);

      thunkAPI.dispatch(getOutstandingTasks());
      thunkAPI.dispatch(getCompletedTasks());
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const reducers = {
  setSelectedTasks: (state: ITasksState, { payload }) => {
    state.selectedTasks = payload;
  },
  setUpdatedTasks: (state: ITasksState, { payload }) => {
    state.updatedTasks = payload;
  },
};

export const tasksSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(getOutstandingTasks.pending, (state) => {
      state.isLoading = true;
      state.outstanding = null;
      state.hasError = false;
      //
    });
    builder.addCase(getOutstandingTasks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.outstanding = action.payload.data.results as ITask[];
      state.hasError = false;
      //
    });
    builder.addCase(getOutstandingTasks.rejected, (state) => {
      state.isLoading = false;
      state.outstanding = null;
      state.hasError = true;
      //
    });
    builder.addCase(getCompletedTasks.pending, (state) => {
      state.isLoading = true;
      state.completed = null;
      state.hasError = false;
      //
    });
    builder.addCase(getCompletedTasks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.completed = action.payload.data.results as ITask[];
      state.hasError = false;
      //
    });
    builder.addCase(getCompletedTasks.rejected, (state) => {
      state.isLoading = false;
      state.completed = null;
      state.hasError = true;
      //
    });
    builder.addCase(putVarifiedTasks.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
      //
    });
    builder.addCase(putVarifiedTasks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      //
    });
    builder.addCase(putVarifiedTasks.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
      //
    });
  },
});
export const selectIsLoading = (state) => state[NAME].isLoading;
export const selectOutstandingTasks = (state) => state[NAME].outstanding;
export const selectCompletedTasks = (state) => state[NAME].completed;

export const selectTasks = (state) => state[NAME].selectedTasks;
export const updateTasks = (state) => state[NAME].updatedTasks;
export const { setSelectedTasks } = tasksSlice.actions;
export const { setUpdatedTasks } = tasksSlice.actions;

export default tasksSlice.reducer;

import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  fetchAllCases,
  setSelectedCase,
  getDocumentCategories,
  selectCasesState,
  CasesPayload,
  getCasePrivicyLevels,
  getDocumentPrivicyLevels,
  getIndustries,
  getMethodologies,
} from "@/redux/casesSlice";
import { columns } from "./Table.data";
import Table from "../Table";
import { ICase, ITableFilter, IData } from "@/redux/store.types";

const MyCasesTableWrapper = () => {
  const appDispatch = useAppDispatch();
  const state = useAppSelector<IData<ICase>>(selectCasesState);
  const { data, pagination, isLoading } = state;

  const [params, setParams] = useState<CasesPayload>({
    skip: 0,
    top: 20,
    sort: "",
    include: "*",
  });

  const onSelectCase = (data: ICase) => {
    appDispatch(setSelectedCase(data));
  };

  const onChangeFilters = (filter: ITableFilter[]) => {
    const args = { ...params };
    if (filter.length > 0) {
      args["filter"] = { items: filter };
    } else {
      delete args["filter"];
    }
    setParams(args);
  };

  const onSortChange = (sort: { sort: string }) => {
    const args = { ...params };
    if (sort.sort) {
      args.sort = sort.sort;
    } else {
      args.sort = "";
    }
    setParams(args);
  };

  const onPaginationChange = (pagination: number) => {
    const args = { ...params };
    args.skip = pagination;
    setParams(args);
  };

  useEffect(() => {
    appDispatch(fetchAllCases(params));
    appDispatch(getDocumentCategories());
    appDispatch(getCasePrivicyLevels());
    appDispatch(getDocumentPrivicyLevels());
    appDispatch(getIndustries());
    appDispatch(getMethodologies());
  }, [params, appDispatch]);

  return (
    <>
      <Typography variant="h5">My Cases</Typography>
      <Table
        height={800}
        data={data}
        columns={columns}
        totalPages={pagination.total}
        pageSize={pagination.top}
        pageNumber={pagination.skip / pagination.top}
        onSelectRow={onSelectCase}
        isLoading={isLoading}
        onFilterChange={onChangeFilters}
        onSortChange={onSortChange}
        onPaginationChange={onPaginationChange}
      />
    </>
  );
};

export default MyCasesTableWrapper;

import { Chip, IconButton, Tooltip } from "@mui/material";
import React from "react";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { formatDate } from "../../utils";
import { Card, RenderIf } from "@adl/smart-ui-library";
import StatusChip from "../StatusChip/StatusChip";
import { useAppSelector } from "@/hooks";
import { selectDocumentCatagories } from "../../redux/casesSlice";

type Props = {
  data: any;
  onSelect: (data: any) => void;
  onDeleteFile: (data: any) => void;
};

const FileCard = ({ data, onSelect, onDeleteFile }: Props) => {
  const date = new Date(data.createdAt).toISOString().split("T")[0];
  const categories = useAppSelector(selectDocumentCatagories);
  const isValidated = data.fileStatus === "verified";
  const Actions = () => (
    <>
      <RenderIf isTrue={false}>
        <Tooltip title="Preview" arrow>
          <IconButton size="small">
            <PreviewOutlinedIcon />
          </IconButton>
        </Tooltip>
      </RenderIf>
      <RenderIf isTrue={false}>
        <Tooltip title="Download" arrow>
          <IconButton size="small">
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </RenderIf>
      <RenderIf isTrue={!isValidated}>
        <Tooltip title="Delete File" arrow>
          <IconButton
            size="small"
            onClick={() => onDeleteFile(data.documentId)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      </RenderIf>
    </>
  );

  return (
    <Card actions={<Actions />}>
      <Card.Title
        onClick={() => {
          onSelect(data);
        }}
      >
        {data.fileNameOriginal || "Original File Name"}
      </Card.Title>
      <Card.Categories>
        <StatusChip status={data.fileStatus} />
        <Chip
          variant="outlined"
          label={`Category: ${
            categories?.find(
              (cat) => cat.documentCategoryId === data.categoryId
            ).name
          }`}
        />
        <Chip variant="outlined" label={`Created: ${formatDate(date)}`} />
      </Card.Categories>
    </Card>
  );
};

export default FileCard;

import React from "react";
import { v4 } from "uuid";
import { getStatusColour } from "@/utils";
import { ITimeLineData } from "../TimeLine";
import * as Styled from "./HorzontalTimeLine.styles";
import { RenderIf } from "@adl/smart-ui-library";
import { TimeLineLine, TimeLineNode } from "../TimeLine.styles";

type Props = {
  direction?: "horizontal" | "vertical";
  data: ITimeLineData[];
  limit?: number;
};

export const HorzontalTimeLine = ({ data, limit = 10 }: Props) => {
  return (
    <Styled.HorzontalTimeLine>
      {data.map((item, index) => (
        <React.Fragment key={v4()}>
          <RenderIf isTrue={index < limit}>
            <Styled.HorzontalTimeLineContent>
              <TimeLineNode bgColour={getStatusColour(item.status)} />
              <Styled.HorzontalTimeLineContentLabel>
                {item.renderer}
              </Styled.HorzontalTimeLineContentLabel>
            </Styled.HorzontalTimeLineContent>
            <RenderIf isTrue={index + 1 !== limit}>
              <TimeLineLine orientation="horizontal" />
            </RenderIf>
          </RenderIf>
        </React.Fragment>
      ))}
    </Styled.HorzontalTimeLine>
  );
};

export default HorzontalTimeLine;

import { Box, styled } from "@mui/system";

export const DocumentList = styled(Box)(() => ({
  marginTop: "20px",
}));

export const DocumentListWrapper = styled(Box)(() => ({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

export const DocumentListWrapperPagination = styled(Box)(() => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
}));

import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import { IMetaData } from "./CaseDetails.data";
import { v4 } from "uuid";
import {
  Filter,
  ICategoriesedFilterMenu,
  RenderIf,
} from "@adl/smart-ui-library";
import { useAppSelector } from "@/hooks";
import { selectIndustries, selectMethodologies } from "@/redux/casesSlice";
import markets from "@/constants/markets";
import currencies from "@/constants/currencies";

type Props = {
  onUpdate: (data: any) => void;
  details: any;
};

const CaseDetails = ({ onUpdate, details }: Props) => {
  const [rowData, setRowData] = React.useState<any[] | null>(null);
  const industriesMap =
    useAppSelector<ICategoriesedFilterMenu[]>(selectIndustries);
  const methodologiesMap =
    useAppSelector<ICategoriesedFilterMenu[]>(selectMethodologies);
  const theme = useTheme();

  useEffect(() => {
    if (!industriesMap || !methodologiesMap || !details) return;

    const { industries, methodologies } = details;

    const matchingIndustries = industriesMap.flatMap((category) =>
      category.items.filter((item) => industries?.includes(item.value))
    );

    const matchingMethodologies = methodologiesMap.flatMap((category) =>
      category.items.filter((item) => methodologies?.includes(item.value))
    );

    const rows = [
      {
        id: "Industries",
        options: industriesMap,
        value: matchingIndustries,
      },
      {
        id: "Methodologies",
        options: methodologiesMap,
        value: matchingMethodologies,
      },
      {
        id: "Markets",
        options: markets,
        value: [
          {
            type: "markets",
            label: details?.markets,
            value: details?.markets,
            param: details?.markets,
          },
        ],
      },
      {
        id: "Budget Currency",
        options: currencies,
        value: [
          {
            type: "currency",
            label: details?.currency,
            value: details?.currency,
            param: details?.currency,
          },
        ],
      },
    ];

    setRowData(rows);
  }, [industriesMap, methodologiesMap, details]);

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: "15px" }}>
        Case Details
      </Typography>
      <TableContainer component={Box}>
        <Table
          sx={{
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <TableBody>
            {rowData?.map((row) => {
              return (
                <TableRow
                  key={v4()}
                  sx={{
                    "td, th": {
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    },
                  }}
                >
                  <TableCell sx={{ width: "150px" }}>
                    <Typography variant="h3">{row.id}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                      {row.value.map((item) => (
                        <Chip key={v4()} label={item.label} />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <RenderIf
                      isTrue={
                        row.id === "Budget Currency" || row.id === "Markets"
                          ? false
                          : true
                      }
                    >
                      <Filter
                        title={`Add`}
                        onApplyFilter={(filters) => {
                          const clone = { ...details };
                          const map = filters.map((filter) => {
                            return filter.value;
                          });
                          clone[row.id.toLowerCase()] = map;
                          onUpdate(clone);
                        }}
                        multiple={row.id === "Budget Currency" ? false : true}
                        search
                        selectedItems={row.value}
                        config={row.options}
                      />
                    </RenderIf>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default CaseDetails;

/*
      
      */

import React from "react";
import {
  Box,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { v4 } from "uuid";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import { RenderIf } from "@adl/smart-ui-library";
import { formatDate, getStatusColour, getStatusText } from "../../utils";
import StatusChip from "../StatusChip/StatusChip";
import AddIcon from "@mui/icons-material/Add";

export const RenderCaseMetaDataAdd = ({ value, addItem }) => {
  return (
    <Tooltip title="Add" arrow>
      <IconButton size="small" onClick={() => addItem(value)}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export const RenderCaseMetaDataTitle = (params) => {
  return (
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
      {params.value}
    </Typography>
  );
};

export const RenderCaseMetaData = (params) => {
  return (
    <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
      {params.value.map((item) => (
        <Chip key={v4()} label={item} />
      ))}
    </Box>
  );
};

export const RenderDateTime = ({ value }) => {
  const date = value?.split("T");

  return (
    <Box>
      {formatDate(date[0])} - {date[1]?.split(".")[0]}
    </Box>
  );
};

export const RenderUploadStatus = ({ value }) => {
  return <StatusChip status={value} />;
};

export const RenderActions = ({ value }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <RenderIf isTrue={false}>
        <Tooltip title="Preview" arrow>
          <IconButton size="small">
            <PreviewOutlinedIcon />
          </IconButton>
        </Tooltip>
      </RenderIf>
      <RenderIf isTrue={false}>
        <Tooltip title="Download" arrow>
          <IconButton size="small">
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </RenderIf>
      <Tooltip title="Delete File" arrow>
        <IconButton size="small">
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const RenderName = ({ value }) => {
  const splitName = value?.split(":");
  return (
    <Box
      sx={{
        display: "grid",
        alignContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {splitName?.map((name) => (
        <Typography key={v4()}>{name}</Typography>
      ))}
    </Box>
  );
};

export const RenderStatus = ({ value }) => {
  return (
    <Box
      sx={{
        display: "grid",
        alignContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "20px",
          display: "flex",
          gap: "2px",
        }}
      >
        <Tooltip title={getStatusText("Proposal", value.proposal)} arrow>
          <Box
            sx={{
              flex: 1,
              height: "100%",
              backgroundColor: getStatusColour(value.proposal),
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
        <Tooltip title={getStatusText("Contract", value.contract)} arrow>
          <Box
            sx={{
              flex: 1,
              height: "100%",
              backgroundColor: getStatusColour(value.contract),
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
        <Tooltip
          title={getStatusText("Materials", value.project_material)}
          arrow
        >
          <Box
            sx={{
              flex: 1,
              height: "100%",
              backgroundColor: getStatusColour(value.project_material),
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
        <Tooltip title={getStatusText("Qual", value.qual)} arrow>
          <Box
            sx={{
              flex: 1,
              height: "100%",
              backgroundColor: getStatusColour(value.qual),
              borderRadius: "5px",
            }}
          ></Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export const CustomInputComponent = (props) => {
  const { item, applyValue } = props;

  const handleChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <TextField
      value={item.value || ""}
      onChange={handleChange}
      placeholder="Custom filter"
      size="small"
    />
  );
};

export const TestRender = () => {
  return (
    <Box
      sx={{
        display: "grid",
        alignContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography>TEST</Typography>
    </Box>
  );
};

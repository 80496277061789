import React, { useEffect, useState } from "react";
import * as Styled from "./MyTasksWrapper.styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskCard from "@/components/TaskCard";
import { Show } from "@adl/smart-ui-library";
import { useAppDispatch, useAppSelector } from "@/hooks";
import LoadingScreen from "@/components/LoadingScreen";
import {
  getOutstandingTasks,
  getCompletedTasks,
  selectOutstandingTasks,
  selectCompletedTasks,
  selectIsLoading,
  putVarifiedTasks,
} from "@/redux/tasksSlice";
import {
  selectDocumentCatagories,
  selectDocumentPrivacyLevels,
} from "@/redux/casesSlice";
import { v4 } from "uuid";
import { motion } from "framer-motion";
import { ANNIMATION_SPEED } from "../../constants";

const MyTasksWrapper = () => {
  const appDispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const tasks = useAppSelector(selectOutstandingTasks);
  const doneTasks = useAppSelector(selectCompletedTasks);
  const categories = useAppSelector(selectDocumentCatagories);
  const privacyLevels = useAppSelector(selectDocumentPrivacyLevels);

  const handleVerify = async (payload) => {
    appDispatch(putVarifiedTasks(payload));
  };

  useEffect(() => {
    appDispatch(getOutstandingTasks());
    appDispatch(getCompletedTasks());
  }, [appDispatch]);

  return (
    <Styled.MyTasksWrapper>
      <Styled.MyTasksWrapperLeft id="document-profile-left">
        <Typography variant="h5">My Tasks</Typography>
        <Show>
          <Show.When isTrue={isLoading}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: ANNIMATION_SPEED }}
            >
              <LoadingScreen />
            </motion.div>
          </Show.When>
          <Show.Else>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: ANNIMATION_SPEED }}
            >
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h6">To Do</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Show>
                    <Show.When isTrue={!tasks?.length}>
                      <Alert severity="info">
                        You have no outstanding tasks
                      </Alert>
                    </Show.When>
                    <Show.Else>
                      {tasks?.map((task) => (
                        <TaskCard
                          key={v4()}
                          task={task}
                          handleVerify={(p) => handleVerify(p)}
                          privacyLevels={privacyLevels}
                          fileCategories={categories}
                        />
                      ))}
                    </Show.Else>
                  </Show>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography variant="h6">Done</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Show>
                    <Show.When isTrue={!doneTasks?.length}>
                      <Alert severity="info">You have no completed tasks</Alert>
                    </Show.When>
                    <Show.Else>
                      {doneTasks?.map((task) => (
                        <TaskCard key={v4()} task={task} />
                      ))}
                    </Show.Else>
                  </Show>
                </AccordionDetails>
              </Accordion>
            </motion.div>
          </Show.Else>
        </Show>
      </Styled.MyTasksWrapperLeft>
    </Styled.MyTasksWrapper>
  );
};

export default MyTasksWrapper;

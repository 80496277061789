import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const VerticalTimeLine = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: "10px",
  alignItems: "flex-start",
  marginBottom: "10px",
}));

export const VerticalTimeLineContent = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  gap: "10px",
}));

export const VerticalTimeLineContentLeft = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "5px",
}));

export const VerticalTimeLineContentRight = styled(Box)(() => ({
  flex: 1,
  paddingBottom: "20px",
}));

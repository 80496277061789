import { Alert, Box, Typography } from "@mui/material";
import React from "react";
import { RenderIf, Show } from "@adl/smart-ui-library";
import * as Styled from "./TaskHeader.styles";
import { ITask } from "@/redux/store.types";

type Props = {
  task: ITask;
};

const TaskHeader = ({ task }: Props) => {
  const { verified, document } = task;
  const { fileNameOriginal } = document;

  return (
    <Styled.TaskHeader>
      <Styled.TaskHeaderTitle>
        <Typography variant="h6">{fileNameOriginal}</Typography>

        <RenderIf isTrue={!verified}>
          <Alert severity="error">Needs validating</Alert>
        </RenderIf>
      </Styled.TaskHeaderTitle>
      <Box>
        <Show>
          <Show.When isTrue={verified}>
            <Styled.TaskHeaderVarifiedIcon />
          </Show.When>
          <Show.Else>
            <Styled.TaskHeaderUnvarifiedIcon />
          </Show.Else>
        </Show>
      </Box>
    </Styled.TaskHeader>
  );
};
export default TaskHeader;

import { ICaseDocSatus } from "../redux/casesSlice";
import { getTheme } from "../themes";
import _ from "lodash";

export const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("");
};

export const capitalizeFirstLetters = (str: string): string => {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const formatDate = (date: string): string => {
  if (!date) return "";
  const splitDate = date.split("-");
  const day = splitDate[2].split("T")[0];
  const month = splitDate[1];
  const year = splitDate[0];
  return `${day}/${month}/${year}`;
};

export const formatTime = (date: string): string => {
  const splitDate = date.split("T")[1].split(":");
  return `${splitDate[0]}:${splitDate[1]}:${splitDate[2].split(".")[0]}`;
};

export const formatDateTime = (date: string): string => {
  return `${formatDate(date)} ${formatTime(date)}`;
};

export const getStatusColour = (params: {
  present: boolean;
  verified: boolean;
}) => {
  if (!params) return;
  const theme = getTheme();
  const { present, verified } = params;
  if (present && verified) return theme.palette.success.main;
  if (present && !verified) return theme.palette.warning.main;
  return theme.palette.grey[400];
};

export const getStatusText = (title: string, data: ICaseDocSatus) => {
  if (title !== "Qual") {
    if (!data.present) {
      return `${title} (Required)`;
    }
    if (!data.verified) {
      return `${title} (Pending Validation)`;
    }
    return `${title} Validated`;
  }
  return `${title}`;
};

export const camelCaseToWords = (input: string): string => {
  return input.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const areObjectsEqualUnordered = (objA, objB) => {
  const sortedObjA = _.mapValues(objA, (value) =>
    Array.isArray(value) ? _.sortBy(value) : value
  );
  const sortedObjB = _.mapValues(objB, (value) =>
    Array.isArray(value) ? _.sortBy(value) : value
  );
  return _.isEqual(sortedObjA, sortedObjB);
};

import React from "react";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { motion } from "framer-motion";
import { PageHeader, PageContent } from "@adl/smart-ui-library";
import MyCasesTableWrapper from "@/components/MyCasesTableWrapper";
import DefaultTemplate from "@/templates/DefaultTemplate/DefaultTemplate";
import NotificationDropdown from "@/components/Notifications/Notifications";
import { useAppDispatch } from "@/hooks";
import { setSelectedTasks } from "@/redux/tasksSlice";
import VaultIcon from "@/components/VaultIcon";
import * as Styled from "./MyCasesPage.styles";
import VaultTextLogo from "@/components/VaultTextLogo";

const MyCasesPage = () => {
  const { instance } = useMsal();
  const appDispatch = useAppDispatch();

  const onLogout = () => {
    instance.logout();
  };

  const onTaskBtnClick = () => {
    appDispatch(setSelectedTasks([]));
  };

  return (
    <DefaultTemplate>
      <PageHeader
        logo={<VaultIcon width={40} height={40} />}
        navigation={
          <>
            <NotificationDropdown />
            <Button onClick={onTaskBtnClick}>Tasks</Button>
            <Button onClick={onLogout}>Logout</Button>
          </>
        }
      >
        <VaultTextLogo />
      </PageHeader>
      <PageContent>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0 }}
        >
          <Styled.DashboardContent>
            <MyCasesTableWrapper />
          </Styled.DashboardContent>
        </motion.div>
      </PageContent>
    </DefaultTemplate>
  );
};

export default MyCasesPage;

import React from "react";
import { LoadingSpinner } from "@adl/smart-ui-library";
import * as Styled from "./LoadingScreen.styles";

const LoadingScreen = () => {
  return (
    <Styled.LoadingScreen>
      <LoadingSpinner />
    </Styled.LoadingScreen>
  );
};

export default LoadingScreen;
